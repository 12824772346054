
.sh-container {
    height: 100%;
    width: 100%;
    /* background-image: url(../assets/death/suicideroom.jpg);
    background-repeat: none;
    background-size: cover; */
    background-color: black;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}


.sh-table-content {
    padding-top: 5vh;
    width: 1000px;
    float: center;
    margin-left: auto;
    margin-right: auto;
}

.sh-table-content table {
    margin-top: 5vh;
    filter: drop-shadow(rgb(255, 255, 255) 0px 0px 3px);
}

.sh-table-content figure {
    margin: 0;
    width: 200px;
}

.sh-table-content td {
    background-color: white;
}

.sh-date-col {
    background-color: white;
    width: 80px;
}

.sh-image {
    width: 100%;
    padding: 3px;
    padding-left: 1vw;
    padding-right: 1vw;
    cursor: pointer;
    transition: all 0.5s ease;
}

.sh-image:hover {
    filter: drop-shadow(red 0px 0px 3px);
}

.sh-content-col {
    border: 3px solid black;
}

.sh-date-col {
    width: 50px;
    border: 3px solid black;
}

.sh-content {
    font-size: 12px;
    padding-left: 1vw;
    padding-right: 1vw;
    color: red;
}

.sh-date {
    font-size: 12px;
    text-decoration: underline;
    color: red;
    font-weight: bold;
}


.pagination-button {
    background-color: #dcdcdc;
    border: 1px solid #999;
    box-shadow: 2px 2px 0px #fff inset, -1px -1px 0px #666 inset;
    padding: 5px 10px;
    margin: 0 10px;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    color: black;
    cursor: pointer;
  }
  
  .pagination-button:active {
    box-shadow: 2px 2px 0px #666 inset, -1px -1px 0px #fff inset;
  }
  
  .pagination-button:disabled {
    color: #aaa;
    cursor: not-allowed;
    box-shadow: 2px 2px 0px #ddd inset, -1px -1px 0px #eee inset;
  }
  
  /* Centered page numbers */
  .pagination-pages {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    display: inline-block;
  }
  
  .pagination-number {
    margin: 0 5px;
    padding: 3px 5px;
    background-color: #fff;
    border: 1px solid #999;
    box-shadow: 1px 1px 0px #ccc inset, -1px -1px 0px #fff inset;
    color: black;
    cursor: pointer;
  }
  
  .pagination-dots {
    margin: 0 5px;
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }

  .pagination-bar {
    margin-right: auto;
    margin-left: auto;
  }