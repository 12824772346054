.gore-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.background-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
}

.middle-png {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%; /* 30% of the screen width */
    z-index: 2;
    cursor: pointer;
    
  }
  
  .middle-png:hover {
    transform: translate(-50%, -50%) scale(1.1);
    filter: drop-shadow(rgb(255, 255, 255) 0px 0px 3px);
  }

  .non-clickable {
    pointer-events: none;
}