/* Container to hold the layout */
.porn-container {
    display: flex;
    width: 100%; /* Full viewport height */
    background-color: rgb(187, 0, 0);
  }
  
  /* Sidebar styles */
  .prn-sidebar {
    width: 18%; /* Adjust the width as needed */
    /* background-color: #f0f0f0; */
    height: 100vh;
    padding: 8px;
    box-sizing: border-box;
    background-color: rgb(187, 0, 0);
    margin-top: 0;
    position: sticky;
    top: 0;
  }
  
  .left {
    border-right: 2px solid #ccc; /* Optional border for separation */
  }
  
  .right {
    border-left: 2px solid #ccc; /* Optional border for separation */
  }
  
  /* Main content styles */
  .prn-main-content {
    flex: 1; /* This will make the middle section take up remaining space */
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto; /* Allows scrolling if content exceeds screen height */
    background-color: rgb(187, 0, 0);
    display: flex;
    flex-direction: column;
  }

  .prn-logo {
    width: calc(100% + 40px);
    padding-bottom: 2vh;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 20px; 
    object-fit: contain;
    cursor: pointer;
  }

  .prn-logo img {
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .prn-logo h1 {
    text-align: center;
    margin-top: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 20px;
    text-decoration: underline;
    color: rgb(218, 218, 21);
    text-shadow: 1px 2px 0 #0000ff; 
    cursor: pointer;
  }

  .side-gif {
    width: 100%;
    margin-bottom: 1vh;
    cursor: pointer;
  }

  .content-columns {
    display: flex;
    width: 100%; /* Full width for both columns together */
  }
  
  /* Individual column styles */
  .column {
    flex: 1; /* Make both columns take equal width */
    padding: 0px 20px 20px 20px;
    box-sizing: border-box;
    width: 50%
  }

  .thumbnail-pic {
    width: 100%;
    transition: all 0.7s;
  }

  .thumbnail-pic:hover {
    transform: scale(1.3);
    cursor: pointer;
  }


  .thumbnail-container h1 {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(218, 218, 21);
    text-shadow: 1px 2px 0 #0000ff; 
    font-weight: 200;
    font-size: 13px;
    cursor: pointer;
  }
  
  .dancer {
    position: fixed;
    top: 0;
    height: 100%;
    width: auto;
    z-index: 999;
    pointer-events: none;
  }

  .dancer img {
    width: 110%;
    transition: opacity 1s ease-in-out; 
    pointer-events: none;
  }

  .full-screen-gif {
    cursor: pointer;
  }

  .full-screen-gif:hover {
    cursor: pointer;
  }

.fade-in {
    opacity: 1; /* GIF is visible */
}

.fade-out {
    opacity: 0; /* GIF is fading out */
}
  

.prn-pagination-button {
    background-color: #dcdcdc;
    border: 1px solid #999;
    box-shadow: 2px 2px 0px #fff inset, -1px -1px 0px #666 inset;
    padding: 3px 7px;
    margin: 0 5px;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 11px;
    color: black;
    cursor: pointer;
  }
  
  .prn-pagination-button:active {
    box-shadow: 2px 2px 0px #666 inset, -1px -1px 0px #fff inset;
  }
  
  .prn-pagination-button:disabled {
    color: #aaa;
    cursor: not-allowed;
    box-shadow: 2px 2px 0px #ddd inset, -1px -1px 0px #eee inset;
  }
  
  /* Centered page numbers */
  .prn-pagination-pages {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 11px;
    display: inline-block;
  }
  
  .prn-pagination-number {
    margin: 0 5px;
    padding: 3px 5px;
    background-color: #fff;
    border: 1px solid #999;
    box-shadow: 1px 1px 0px #ccc inset, -1px -1px 0px #fff inset;
    color: black;
    cursor: pointer;
  }
  
  .prn-pagination-dots {
    margin: 0 2px;
    font-size: 9px;
    color: #666;
    cursor: pointer;
  }

  .prn-pagination-bar {
    width: 150%;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000;
    pointer-events: auto;
  }

  .dm-container {
    width: 30%;
    padding: 0;
    position: fixed; /* Keep the modal fixed relative to the viewport */
    bottom: 0px; /* Start positioning from the vertical center */
    right: 0px; /* Start positioning from the horizontal center */
    pointer-events: none;
    z-index: 2000;
    pointer-events: all;
  }

  .dm {
    width: 100%;
    height: auto;
    z-index: 2000;
    /* display: block; */
    transition: all 0.3s ease;
    pointer-events: all;
  }

  .dm:hover {
    box-shadow: 0 0 15px 15px red;
    cursor: pointer;
  }
