.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Highest z-index to overlay everything */
}

.loading-gif {
    height: auto;
    object-fit: contain;
}
