/* homepage.css */
.background {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    transition: background-color 0.5s ease;
    background-size: cover;

  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
  }

  .click-button {
    position: absolute;
    bottom: 25%; /* 3/4th of the height */
    left: 50%;
    transform: translateX(-50%);
    width: 13vw; /* Adjust size as needed */
    height: auto;
    transition: all 0.7s ease;
    cursor: pointer;
  }
  
  .click-button:hover {
    box-shadow: 0 0 15px 15px red;
  }
  
  .click-button:active {
    transform: translateX(-50%) scale(0.80); /* Slightly smaller on click */
  }

  .loading-gif-1 {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12vw; /* Adjust size based on viewport width */
    height: auto;
  }

  .loading-gif-2 {
    position: absolute;
    top: 0;
    left: 0;
    /* padding: 0;
    margin: 0; */
    /* transform: translate(-50%, -50%); */
    width: 30vw; /* Adjust size based on viewport width */
    height: auto;
  }

  .loading-gif-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 12vw; /* Adjust size based on viewport width */
    height: auto;
  }

  .loading-gif-4 {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20vw; /* Adjust size based on viewport width */
    height: auto;
  }

  .loading-gif-5 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20vw; /* Adjust size based on viewport width */
    height: auto;
  }

  .loading-gif-6 {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 20vw; /* Adjust size based on viewport width */
    height: auto;
  }

  .round-loading {
    position: fixed;
    inset: 0px;
    max-width: 100vw;
    max-height: 100dvh;
    z-index: 2;
    width: 8vw; /* Adjust size based on viewport width */
    height: auto;
    margin: auto;
  }

  .logo {
    position: absolute;
    bottom: 4%; /* 3/4th of the height */
    left: 95%;
    transform: translateX(-50%);
    width: 10vw; /* Adjust size as needed */
    height: auto;
    transition: transform 0.6s ease-in-out, box-shadow 0.3s ease;
  }

  .forum-button {
    position: absolute;
    bottom: 2%; /* 3/4th of the height */
    left: 5%;
    transform: translateX(-50%);
    width: 8vw; /* Adjust size as needed */
    height: auto;
    transition: box-shadow 0.3s ease;
  }
  
  .forum-button:active {
    transform: translateX(-50%) scale(0.80); /* Slightly smaller on click */
  }

  .custom-cursor {
    position: absolute;
    width: 20px; /* Adjust cursor size */
    height: 20px; /* Adjust cursor size */
    pointer-events: none; /* Prevent cursor from interacting with elements */
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10000; /* Ensure the cursor is above all other elements */
  }

  .background.hide-default-cursor {
    cursor: none; /* Hide the default cursor */
  }